// Copyright 2023 Immersive Technologies Pty Ltd. All rights reserved.

export enum LanguageSupported {
  'en-AU' = 'English (Australia)',
  'es-CL' = 'Spanish',
  'fr-FR' = 'French',
}

/**
 * Provide Language name in the specified language
 * @param key The language key
 */
export const getLanguagesInOwnLanguage = (key: keyof typeof LanguageSupported) => {
  switch (key) {
    case 'es-CL':
      return 'Español';
    case 'fr-FR':
      return 'Français';
    default:
      return 'English (Australia)';
  }
};

export const DEFAULT_LANGUAGE = 'en-AU';
export const SUPPORTED_LANGUAGES = Object.keys(LanguageSupported);
