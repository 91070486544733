// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.
import Dialog from '@mui/material/Dialog';
import { ErrorCode, getErrorMessage } from '../../utils/errorUtils';
import { useEffect, useState } from 'react';
import { Alert, Box, Button, CssBaseline, DialogContent, DialogTitle, ThemeProvider, useTheme } from '@mui/material';
import { t } from '../../i18n/i18n';
import { sendResetEmail } from '../../utils/authServiceUtils';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { DEFAULT_LANGUAGE } from '../../i18n/languages';
import { EmailSuccessPage } from '../ForgotPassword/ForgotPwdPages/EmailSuccessPage';
import { ReturnToLoginLink } from '../Common/ReturnToLoginLink';
import { forgotPwdTheme } from '../Themes/Themes';
import Logo from '../Logo';

interface IResetPwsState {
  errorCode: ErrorCode | null;
  emailSentStatus: EmailStatus;
}

type EmailStatus = 'unsent' | 'sending' | 'sent';

export const MigratedUser = () => {
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get('redirectTo');
  const lang = searchParams.get('lang') ?? DEFAULT_LANGUAGE;
  const location = useLocation();
  const email = location.state;
  const navigate = useNavigate();
  const theme = useTheme();

  const [resetPwdState, setResetPwdState] = useState<IResetPwsState>({
    errorCode: null,
    emailSentStatus: 'unsent',
  });

  useEffect(() => {
    // if the email or redirectTo are not set then navigate to an error page
    if (!redirectTo || !email) {
      return navigate('/error/unknown');
    }
  }, [email, navigate, redirectTo]);

  const onEnter = async () => {
    try {
      setResetPwdState({ ...resetPwdState, errorCode: null, emailSentStatus: 'sending' });
      await sendResetEmail(email, redirectTo!, lang || DEFAULT_LANGUAGE);
      setResetPwdState({ ...resetPwdState, errorCode: null, emailSentStatus: 'sent' });
    } catch (err) {
      setTimeout(
        () => setResetPwdState({ ...resetPwdState, errorCode: ErrorCode.EmailSendFailed, emailSentStatus: 'unsent' }),
        750
      );
    }
  };

  return (
    <ThemeProvider theme={forgotPwdTheme}>
      <CssBaseline />
      <Dialog open>
        <Logo />
        <Box>
          <DialogTitle id="form-dialog-title">
            {t({ defaultMessage: `User Account has been Migrated`, id: 'DUMwP4' })}
          </DialogTitle>
          <DialogContent>
            <Box>
              <Alert severity="info" variant="outlined">
                {t({
                  defaultMessage: `Your password has expired. Please reset it to continue.`,
                  id: '6QPQnR',
                })}
              </Alert>
              <Box sx={{ marginTop: theme.spacing(2) }}>
                {resetPwdState.emailSentStatus !== 'sent' ? (
                  <Box>
                    {resetPwdState.errorCode && (
                      <Alert severity="error">{getErrorMessage(resetPwdState.errorCode)}</Alert>
                    )}
                    <Button
                      className="send-link"
                      fullWidth
                      disableElevation
                      variant={'contained'}
                      color="primary"
                      disabled={resetPwdState.emailSentStatus === 'sending'}
                      onClick={onEnter}>
                      {t({
                        defaultMessage: 'Send reset password link',
                        id: 'd0I0o0',
                      })}
                    </Button>
                  </Box>
                ) : (
                  <EmailSuccessPage email={email} />
                )}
              </Box>
              <ReturnToLoginLink />
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </ThemeProvider>
  );
};
