// Copyright 2023 Immersive Technologies Pty Ltd. All rights reserved.

import { Account, Auth, MfaSetup, LoggedInAccount, Sso } from '../types/Account.types';
import getAppConfig from './configUtils';
import { ErrorCode } from './errorUtils';
import { fetchGet, fetchPost } from './httpUtils';

let backendApiUrl: string | undefined;

// Get the url for backend API calls
export const getBackendApiUrl = async () => {
  if (!backendApiUrl) {
    backendApiUrl = (await getAppConfig()).serverUrl;
  }
  return backendApiUrl;
};

// Check the account to see if it's an enterprise user or a migrated account
export const checkAccount = async (email: string): Promise<Account> => {
  const requestURL = `${await getBackendApiUrl()}/api/check-account`;
  const body = JSON.stringify({
    username: email,
  });
  const result = await fetchPost<Account>(`${requestURL}`, { body, headers: {} });
  return result;
};

// Redirect to the auth service login endpoint
export const redirectToEnterpriseLogin = async (payload?: Record<string, string>) => {
  const params = new URLSearchParams();
  Object.entries(payload ?? {}).forEach(([name, value]) => {
    params.set(name, value);
  });

  const enterpriseLoginAPI = `${await getBackendApiUrl()}/api/login?${params.toString()}`;
  window.location.href = enterpriseLoginAPI;
};

// Validate user credentials using Cognito
export const loginWithCognito = async (
  username: string,
  password: string,
  csrfToken: string,
  redirectTo: string
): Promise<Auth> => {
  const requestURL = `${await getBackendApiUrl()}/api/authorize`;
  const body = JSON.stringify({
    username,
    password,
    csrfToken,
    redirectTo,
  });
  return fetchPost<Auth>(requestURL, { body, headers: {} }, [
    {
      status: 401,
      errorCode: ErrorCode.InvalidLoginDetails,
    },
  ]);
};

// Validate user credentials using MFA
export const validateUserWithMFA = async (
  pin: string,
  session: string,
  username: string,
  csrfToken: string
): Promise<Auth> => {
  const requestURL = `${await getBackendApiUrl()}/api/mfa/challenge`;
  const body = JSON.stringify({
    pin,
    session,
    username,
    csrfToken,
  });
  return fetchPost<Auth>(requestURL, { body, headers: {} }, [
    {
      // The auth service backend explicitly handles invalid MFA code errors by returning a 401 status code.
      // While other errors may also result in a 401 status code, they are unlikely to occur for this request.
      status: 401,
      errorCode: ErrorCode.InvalidMFACode,
    },
    {
      status: 408,
      errorCode: ErrorCode.SessionExpired,
    },
  ]);
};

// Retrieve the user's login token based on the cookie
export const getLoginToken = async (csrfToken: string): Promise<string> => {
  const requestURL = `${await getBackendApiUrl()}/api/sso`;
  const body = JSON.stringify({
    csrfToken,
  });
  const token = await fetchPost<Sso>(requestURL, { body, headers: {} }, []).then((res) => res.token);
  return token;
};

// Redirect users back to the product
export const redirectBackToProduct = (redirectUrl: string, params: Record<string, string>) => {
  // Extract the base and search params from the redirect url
  const [baseUrl, searchParams] = redirectUrl.split('?');
  const newParams = new URLSearchParams(searchParams);

  // Append new params to the redirect url
  Object.entries(params).forEach(([name, value]) => {
    newParams.set(name, value);
  });

  window.location.href = `${baseUrl}?${newParams.toString()}`;
};

// Update Cognito user credentials
export const updateCognitoCredentials = async (oldPassword: string, newPassword: string) => {
  const requestURL = `${await getBackendApiUrl()}/api/change-password`;
  const body = JSON.stringify({
    oldPassword,
    newPassword,
  });
  await fetchPost(requestURL, { body, headers: {} }, [
    {
      status: 400,
      errorCode: ErrorCode.PasswordUpdateFailed,
    },
  ]);
};

// Send reset password email
export const sendResetEmail = async (email: string, redirectTo: string, lang: string) => {
  const requestURL = `${await getBackendApiUrl()}/api/forgot-password`;
  const body = JSON.stringify({
    email,
    redirectTo,
    lang,
  }) as BodyInit;
  return fetchPost(requestURL, { body, headers: {} });
};

// Get the MFA setup code for the user
export const getMFASetupCode = async (): Promise<MfaSetup> => {
  const requestURL = `${await getBackendApiUrl()}/api/mfa/setup`;
  const body = JSON.stringify({}) as BodyInit;
  return fetchPost<MfaSetup>(requestURL, { body, headers: {} });
};

export const confirmMFASetup = async (pin: string) => {
  const requestURL = `${await getBackendApiUrl()}/api/mfa/confirm`;
  const body = JSON.stringify({ pin }) as BodyInit;
  return fetchPost(requestURL, { body, headers: {} }, [
    {
      status: 400,
      errorCode: ErrorCode.InvalidMFACode,
    },
  ]);
};

// Check if the recovery code is valid
export const validateResetCode = async (tokenId: string) => {
  const requestURL = `${await getBackendApiUrl()}/api/validate-reset-code`;
  const params = new URLSearchParams({ tokenId });
  return fetchGet(`${requestURL}?${params.toString()}`, { headers: {} }).then((res) => res.isValid);
};

// Reset password
export const resetPassword = async (tokenId: string, code: string, newPassword: string) => {
  const requestURL = `${await getBackendApiUrl()}/api/reset-password`;
  const body = JSON.stringify({
    code,
    tokenId,
    newPassword,
  }) as BodyInit;
  return fetchPost(requestURL, { body, headers: {} });
};

// Reset password for the new user
export const resetPasswordForNewUser = async (
  tokenId: string,
  username: string,
  pwd: string,
  redirectTo: string,
  newPassword: string
) => {
  const requestURL = `${await getBackendApiUrl()}/api/authorize`;
  const body = JSON.stringify({
    tokenId,
    username,
    password: pwd,
    redirectTo,
    newPassword,
  }) as BodyInit;
  return fetchPost(requestURL, { body, headers: {} });
};

// Check if the user has enabled MFA
export const checkMFAStatus = async () => {
  const requestURL = `${await getBackendApiUrl()}/api/account`;
  return fetchPost<LoggedInAccount>(requestURL, { body: '', headers: {} }).then((res) => res.isMFAEnabled);
};
