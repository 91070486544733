// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.

import React from 'react';
import { CssBaseline, Dialog, DialogContent, DialogTitle, Grid, ThemeProvider } from '@mui/material';
import { ErrorCode } from '../../utils/errorUtils';
import ErrorIcon from '@mui/icons-material/Error';
import { useParams } from 'react-router-dom';
import { AccountDoesNotExist } from './ErrorPages/AccountDoesNotExist';
import { UnknownError } from './ErrorPages/UnknownError';
import { alertTheme } from '../Themes/Themes';
import { t } from '../../i18n/i18n';

// Render the error page based on error code extracted from the path
const RenderErrorPage = ({ errorCode }: { errorCode: string | undefined }) => {
  switch (errorCode) {
    case ErrorCode.AccountDoesNotExist:
      return <AccountDoesNotExist />;
    default:
      return <UnknownError />;
  }
};

const ErrorPage: React.FC = () => {
  const { errorCode } = useParams();
  return (
    <ThemeProvider theme={alertTheme}>
      <CssBaseline />
      <Dialog open>
        <Grid container direction="column" alignItems="center" justifyContent="center">
          <ErrorIcon />
        </Grid>
        <DialogTitle>
          {t({
            defaultMessage: 'Error',
            id: 'KN7zKn',
          })}
        </DialogTitle>
        <DialogContent>
          <RenderErrorPage errorCode={errorCode} />
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

export default ErrorPage;
