// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.
import { Container, Grid, Link as MuiLink, ThemeProvider, Typography } from '@mui/material';
import { footerTheme } from '../Themes/Themes';
import { t } from '../../i18n/i18n';
import Logo from '../Logo';

const Footer = () => {
  return (
    <ThemeProvider theme={footerTheme}>
      <Container>
        <Grid container>
          {/* Left Content */}
          <Grid item>
            <Grid container>
              <Grid item display="flex">
                <Typography variant="body2">
                  {t(
                    {
                      defaultMessage: `Copyright © {year}. Immersive Technologies. All rights reserved.`,
                      id: '3HV21E',
                    },
                    { year: new Date().getFullYear() }
                  )}
                </Typography>
                <MuiLink
                  href="https://licensing.immersiveonline.com/"
                  target="_blank"
                  rel="noopener"
                  underline="always"
                  variant="body2">
                  {t({
                    defaultMessage: 'License',
                    id: 'HBxXD/',
                  })}
                </MuiLink>
              </Grid>
            </Grid>
          </Grid>
          {/* Right Content */}
          <Grid item>
            <Logo footer />
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default Footer;
