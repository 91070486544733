// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.

import { Button } from '@mui/material';
import { t } from '../../i18n/i18n';
import { useNavigate, useSearchParams } from 'react-router-dom';

/**
 * SwitchAccountButton Component
 *
 * This component renders a button that allows the user to switch accounts.
 * When the button is clicked, the user is redirected to the login page with the current query parameters (if any).
 *
 * @param {string} color - The color of the button. Default is 'primary'.
 * @returns {JSX.Element} - The SwitchAccountButton component.
 */
export const SwitchAccountButton = ({
  color = 'primary', // Default value for color is set to 'primary'
}: {
  color?: 'primary' | 'inherit' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined;
  handleClick?: () => void;
}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // Redirect users back to the login page if they want to switch account
  // If the user session is stored in the future, we'd also log the user out of the current account and clear the cookie.
  const onClickSwitchAccount = () => {
    if (window.location.pathname === '/login') {
      window.location.reload();
    } else {
      navigate({ pathname: '/login', search: `?${searchParams.toString()}` });
    }
  };

  return (
    <Button variant="contained" color={color} onClick={onClickSwitchAccount}>
      {t({
        defaultMessage: 'Log in with a different account',
        id: 'ftt8US',
      })}
    </Button>
  );
};
