// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.
import Dialog from '@mui/material/Dialog';
import { Box, CssBaseline, DialogContent, DialogTitle, Link as MuiLink, ThemeProvider, Typography } from '@mui/material';
import { t } from '../../i18n/i18n';
import { faqTheme } from '../Themes/Themes';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useSearchParams } from 'react-router-dom';
import Logo from '../Logo';

type section = 'none' | 'whyMFA' | 'resetMFA' | 'welcomeExpired' | 'emailChanged';

export const FAQ = () => {

  // optionally expand the FAQ section based on the URL params
  const [searchParams] = useSearchParams();
  const section = searchParams.get('section') || 'none' as section;

  return (
    <ThemeProvider theme={faqTheme}>
      <CssBaseline />
      <Dialog open>
      <Logo />
        <Box>
          <DialogTitle
            id="form-dialog-title"
            style={{
              textAlign: 'center',
              fontWeight: 'normal',
              fontStyle: 'normal',
              textDecoration: 'none',
            }}>
            {t({ defaultMessage: `Frequently Asked Questions`, id: 'QA1wrZ' })}
          </DialogTitle>
          <DialogContent>
            <Box>
              <Accordion defaultExpanded={section === 'whyMFA'}>
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography>{t({ defaultMessage: `Why should I setup 2-Step Authentication?`, id: 'bZjDjc' })}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {t({
                      defaultMessage:`2-Step Authentication is an extra layer of security that helps protect your account. While having a strong password is important,
                      2-Step Authentication can greatly increase the security of your account by requiring a second form of verification to log in. This means that even
                      if someone knows your password, they won't be able to access your account without the second form of verification. `,
                      id: 'TzFPVB'
                      })}
                  </Typography>
                  <br />
                  <Typography>
                    {t({
                      defaultMessage:`In order to setup 2-Step Authentication, you will need to download an authenticator app on your phone. We recommend using Google Authenticator or Microsoft Authenticator. `,
                      id: 'pfRNvj'
                      })}
                  </Typography>
                  <br />
                  <Typography>
                    {t({
                      defaultMessage:`2-Step Authentication is highly recommended for all users. For more information, see `,
                      id: 'hxS6Dj'
                      })}
                    <MuiLink
                      href="https://www.cyber.gov.au/protect-yourself/securing-your-accounts/multi-factor-authentication"
                      target="_blank"
                      rel="noopener"
                      color="inherit"
                      aria-label={t({
                        defaultMessage: 'Read more about 2-Step Authentication',
                        id: 'vNKcNi',
                      })}
                      underline="always">
                      {t({
                        defaultMessage: '2-Step Authentication',
                        id: 'vsNhRD',
                      })}
                    </MuiLink>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded={section === 'resetMFA'}>
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography>{t({ defaultMessage: `I can't access my 2-Step Authentication device. How do I reset my 2-Step Authentication?`, id: 'aYf6KQ' })}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {t({
                        defaultMessage:`If you are no longer able to access your 2-Step Authentication device, you can reset your 2-Step Authentication by contacting your training manager, supervisor or Customer Support on `,
                        id: 'ruDuOf'
                        })}
                    <MuiLink
                      href="tel:+61893479099"
                      color="inherit"
                      underline="always">
                        +61 8 9347 9099
                    </MuiLink>
                    {t({
                        defaultMessage:`, or via email at `,
                        id: 'ICHikp'
                        })}
                    <MuiLink
                      href="mailto:Support@ImmersiveTechnologies.com"
                      color="inherit"
                      underline="always">
                        Support@ImmersiveTechnologies.com.
                    </MuiLink>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded={section === 'welcomeExpired'}>
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography>{t({ defaultMessage: `My welcome link has expired. How do I get a new one?`, id: 'P546+a' })}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                  {t({
                      defaultMessage:`If your welcome link has expired you will be unable to access your account. You should contact your training manager or supervisor to resend your invite.`,
                      id: 'FAlT2w'
                      })}
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded={section === 'emailChanged'}>
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography>{t({ defaultMessage: `My email has changed. How do I update it?`, id: 'pyrTLJ' })}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {t({
                      defaultMessage:`You are unable to update your email address yourself through the website. You should contact your training manager, supervisor or Customer Support on `,
                      id: 'f1p+m+'
                    })}
                    <MuiLink
                      href="tel:+61893479099"
                      color="inherit"
                      underline="always">
                        +61 8 9347 9099
                    </MuiLink>
                    {t({
                        defaultMessage:`, or via email at `,
                        id: 'ICHikp'
                        })}
                    <MuiLink
                      href="mailto:Support@ImmersiveTechnologies.com"
                      color="inherit"
                      underline="always">
                        Support@ImmersiveTechnologies.com.
                    </MuiLink>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </ThemeProvider>
  );
};
