// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.

import { alpha, createTheme, darken } from '@mui/material/styles';
import { SignatureColors } from './colors';
import { FontSizes, FontWeights, SupportedFonts } from './fonts';
import backgroundImg from '../../assets/img/background.png';

// Defines the breakpoints for the theme to make it responsive
let baseTheme = createTheme({
  spacing: 4,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
    subtitle1: {
      fontSize: FontSizes.MEDIUM,
      fontWeight: FontWeights.BOLD,
    },
    body2: {
      fontSize: FontSizes.SMALL,
      fontWeight: FontWeights.REGULAR,
    },
    fontFamily: SupportedFonts.join(','),
    fontSize: FontSizes.MEDIUM,
  },
});

baseTheme = createTheme(baseTheme, {
  palette: {
    primary: {
      main: SignatureColors.Yellow['PRIMARY'],
      dark: darken(SignatureColors.Yellow['PRIMARY'], 0.1),
      contrastText: SignatureColors.Black,
    },
    secondary: {
      main: SignatureColors.Grey[100],
      dark: darken(SignatureColors.Grey[100], 0.1),
      contrastText: SignatureColors.Black,
    },
    info: {
      main: SignatureColors.Black,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: SignatureColors.Grey['BACKGROUND_COLOR'],
          fontFamily: baseTheme.typography.fontFamily,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          textAlign: 'center',
          overflowY: 'auto',
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent',
          }, // Remove the additional semi transparent layer for MUI Dialogue
        },
        paper: {
          borderRadius: '3px',
          backgroundColor: SignatureColors.White,
          padding: `${baseTheme.spacing(10)} ${baseTheme.spacing(4)}`,
          boxShadow: '3',
          width: '20%',
          minWidth: '450px',
          minHeight: '600px',
          [baseTheme.breakpoints.down('sm')]: {
            minWidth: '70%', // For smaller screens, use 70% of the screen width
            minHeight: '300px',
            padding: baseTheme.spacing(1),
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          fontSize: FontSizes.XLARGE,
          fontWeight: FontWeights.BOLD,
          [baseTheme.breakpoints.down('sm')]: {
            padding: baseTheme.spacing(1),
            fontSize: FontSizes.SMALL,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          fontWeight: FontWeights.BOLD,
          borderRadius: '3px',
          padding: baseTheme.spacing(3),
          marginTop: baseTheme.spacing(2),
          [baseTheme.breakpoints.down('sm')]: {
            padding: baseTheme.spacing(1),
            fontSize: FontSizes.SMALL,
          },
        },
        textInfo: {
          fontSize: FontSizes.SMALL,
          fontWeight: FontWeights.MEDIUM,
          padding: 0,
          margin: `${baseTheme.spacing(4)} 0`,
          [baseTheme.breakpoints.down('sm')]: {
            fontSize: FontSizes.XSMALL,
            margin: baseTheme.spacing(1),
          },
          '&:hover': {
            backgroundColor: 'transparent', // Remove hover effect for text variant buttons
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: FontSizes.XXXLARGE,
          [baseTheme.breakpoints.down('sm')]: {
            fontSize: FontSizes.LARGE,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '3px',
          backgroundColor: SignatureColors.Grey[100],
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent', // No border color on focus
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent', // No border color on hover
          },
        },
        input: {
          padding: `${baseTheme.spacing(3)} ${baseTheme.spacing(4)}`,
          '&::placeholder': {
            textAlign: 'center', // Center the placeholder text
          },
          [baseTheme.breakpoints.down('sm')]: {
            fontSize: FontSizes.SMALL,
            padding: `${baseTheme.spacing(1)} ${baseTheme.spacing(3)}`,
          },
        },
        notchedOutline: {
          border: 'none', // Remove border
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          display: 'none', // Hide the floating label
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          textAlign: 'center',
          fontSize: FontSizes.SMALL,
          [baseTheme.breakpoints.down('sm')]: {
            fontSize: FontSizes.XXSMALL,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontSize: FontSizes.SMALL,
          boxShadow: 'none',
          width: '150px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: FontSizes.SMALL,
          width: '150px',
          justifyContent: 'center',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          textAlign: 'left',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: 'none',
          backgroundColor: 'transparent',
          color: SignatureColors.Grey[700],
        },
      },
    },
  },
});

// Create a custom theme for the public pages that contains an Immersive background image
const publicPageTheme = createTheme(baseTheme, {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundImage: `url(${backgroundImg})`,
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          backgroundPosition: 'center',
          backgroundSize: 'auto 100vh',
          [baseTheme.breakpoints.down('sm')]: {
            backgroundPosition: '40%',
          },
        },
      },
    },
  },
});

// Create a custom theme for the login page
export const loginTheme = createTheme(publicPageTheme, {
  components: {
    MuiButton: {
      styleOverrides: {
        startIcon: {
          '& .MuiSvgIcon-root': {
            fontSize: FontSizes.XSMALL,
          },
          marginRight: baseTheme.spacing(1),
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        outlinedInfo: {
          backgroundColor: SignatureColors.Grey[50],
          color: SignatureColors.Grey[600],
          border: `solid 1px ${SignatureColors.Grey[100]}`,
          fontSize: FontSizes.MEDIUM,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
  },
});

// Create a custom theme for the forgot password page
export const forgotPwdTheme = createTheme(publicPageTheme, {});

// Create a custom theme for the reset password page
export const resetPwdTheme = createTheme(publicPageTheme, {});

// Create a custom theme for the change password page
export const changePwdTheme = createTheme(baseTheme, {
  components: {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          margin: baseTheme.spacing(4),
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true, // Make all labels "fixed" by default
      },
      styleOverrides: {
        root: {
          display: true,
          fontSize: FontSizes.XLARGE,
          fontWeight: FontWeights.REGULAR,
          color: SignatureColors.Grey[300],
          '&.Mui-focused': {
            color: SignatureColors.Grey[300],
          },
          '&.Mui-error': {
            color: SignatureColors.Grey[300],
          },
          [baseTheme.breakpoints.down('sm')]: {
            fontSize: FontSizes.MEDIUM,
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: `${baseTheme.spacing(2)} 0 ${baseTheme.spacing(6)} 0`,
          [baseTheme.breakpoints.down('sm')]: {
            margin: `${baseTheme.spacing(2)} 0 ${baseTheme.spacing(4)} 0`,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          marginTop: baseTheme.spacing(2),
          [baseTheme.breakpoints.down('sm')]: {
            marginTop: baseTheme.spacing(1),
          },
        },
      },
    },
  },
});

// Create a custom theme for the Setup MFA page
export const setupMFATheme = createTheme(baseTheme, {
  components: {
    MuiGrid: {
      styleOverrides: {
        item: {
          marginBottom: baseTheme.spacing(1),
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          minWidth: '550px',
          minHeight: '100px',
          [baseTheme.breakpoints.down('sm')]: {
            padding: baseTheme.spacing(1),
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          color: SignatureColors.Grey[300],
          justifyContent: 'center',
          textAlign: 'center',
          '&:before': {
            display: 'none', // Remove the divider line
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '0', // Adjust horizontal padding
          minHeight: 'unset', // Remove minimum height restriction
          '&.Mui-expanded': {
            minHeight: 'unset',
          },
          fontSize: FontSizes.MEDIUM,
          textDecoration: 'underline', // Add underline to mimic a link style
        },
        content: {
          justifyContent: 'center', // Centers the summary content
          margin: '0', // Adjust vertical margin
          '&.Mui-expanded': {
            margin: '0',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: baseTheme.spacing(2),
          backgroundColor: SignatureColors.Grey[50],
          color: SignatureColors.Grey[300],
          border: `solid 1px ${SignatureColors.Grey[100]}`,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
  },
});

// Create a custom theme for the profile page
export const profileTheme = createTheme(baseTheme, {
  typography: {
    h5: {
      textAlign: 'left',
      fontSize: FontSizes.LARGE,
      fontWeight: FontWeights.BOLD,
      padding: `${baseTheme.spacing(2)} 0`,
      borderBottom: `1px solid ${alpha(SignatureColors.Black, 0.1)}`,
      marginBottom: baseTheme.spacing(1),
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: FontSizes.MEDIUM,
        padding: `${baseTheme.spacing(1)} 0`,
      },
    },
  },
  components: {
    MuiGrid: {
      styleOverrides: {
        item: {
          marginBottom: baseTheme.spacing(10),
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontSize: FontSizes.MEDIUM,
        },
      },
    },
  },
});

// Create a custom theme for the faq page
export const faqTheme = createTheme(publicPageTheme, {
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          minWidth: '40%',
          minHeight: '70%',

        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          color: SignatureColors.Grey[300],
          padding: '10px',
          border: 'none',
          '&:before': {
            display: 'none', // Remove the divider line
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          fontSize: FontSizes.MEDIUM,
          backgroundColor: SignatureColors.Grey[50],
          textAlign: 'left',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          border: `solid 1px ${SignatureColors.Grey[100]}`,
          textAlign: 'left',
          padding: '10px',
        },
      },
    },
  },
});

// Create a custom theme specifically for alert pages, such as error pages or notifications
export const alertTheme = createTheme(baseTheme, {
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          width: 'auto',
          minHeight: '100px',
          [baseTheme.breakpoints.down('sm')]: {
            minHeight: '100px',
            padding: baseTheme.spacing(1),
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          margin: `${baseTheme.spacing(1)} 0`,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: SignatureColors.Yellow['PRIMARY'],
          fontSize: '100px',
          [baseTheme.breakpoints.down('sm')]: {
            fontSize: FontSizes.LARGE,
          },
        },
      },
    },
  },
});

// Create a theme for the footer

export const footerTheme = createTheme(baseTheme, {
  // Enable custom variants for Box
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(SignatureColors.White, 0.5),
          color: SignatureColors.Black,
          position: 'fixed',
          bottom: 0,
          left: 0,
          minWidth: '100%',
          height: '40px',
          zIndex: 1301, // Set z-index higher than the backdrop
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          height: '100%',
          textAlign: 'center',
          alignItems: 'baseline',
          justifyContent: 'space-between',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: FontSizes.XSMALL,
          color: SignatureColors.Grey[400],
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: SignatureColors.Grey[300],
          textDecoration: 'none',
          marginLeft: baseTheme.spacing(1),
        },
      },
    },
  },
});
