// Copyright 2023 Immersive Technologies Pty Ltd. All rights reserved.

import { Box, Button, DialogContent, DialogTitle, TextField, IconButton, InputAdornment } from '@mui/material';
import { InfoOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { t } from '../../../i18n/i18n';
import { useLoginFormContext } from '../LoginForm';
import { getErrorMessage } from '../../../utils/errorUtils';
import { useState } from 'react';

const PasswordPage = () => {
  const { loginState, onSubmit, onPasswordChange, goBack } = useLoginFormContext();
  const [showPassword, setShowPassword] = useState(false);
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleRedirectToForgotPassword = () => {
    return navigate(`/forgot-password?${searchParams.toString()}`, { state: loginState.username });
  };

  return (
    <Box>
      <DialogTitle>
        <IconButton
          aria-label="back to username page"
          onClick={(e) => {
            e.preventDefault();
            goBack();
          }}>
          <ArrowBackIcon />
        </IconButton>
        {loginState.username}
      </DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          autoFocus
          margin="dense"
          placeholder={t({
            defaultMessage: 'Enter your password',
            id: '2GFjIN',
          })}
          type={showPassword ? 'text' : 'password'}
          autoComplete="current-password"
          fullWidth
          error={loginState.errorCode !== null}
          helperText={getErrorMessage(loginState.errorCode)}
          value={loginState.password}
          onChange={onPasswordChange}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              onSubmit(ev);
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button type="submit" fullWidth onClick={onSubmit} variant={'contained'} disableElevation>
          {t({
            defaultMessage: 'Log in',
            id: 'odXlk8',
          })}
        </Button>

        <Button
          type="button"
          color="info"
          variant="text"
          startIcon={<InfoOutlined />}
          onClick={handleRedirectToForgotPassword}>
          {t({
            defaultMessage: 'Having trouble logging in?',
            id: 'zyI7lS',
          })}
        </Button>
      </DialogContent>
    </Box>
  );
};

export default PasswordPage;
